import { cva } from 'class-variance-authority';

export const cvaLoading = cva(
  [
    'LoadingSpinnerStyles-cvaLoading',
    'text-[2rem]',
    'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[2]',
  ],
  {
    variants: {
      size: {
        xs: ['w-[1.2em] h-[1.2em]'],
        sm: ['w-[1.4em] h-[1.4em]'],
        md: ['w-[1.6em] h-[1.6em]'],
        lg: ['w-[1.8em] h-[1.8em]'],
        xl: ['w-[2em] h-[2em]'],
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

export const cvaCircle = cva(
  [
    'LoadingSpinnerStyles-cvaCircle',
    'overflow-visible',
    'w-full h-full',
    'm-0',
    'stroke-[0.3em] animate-spin',
  ],
  {
    variants: {
      color: {
        black: ['stroke-cBlack'],
        white: ['stroke-cWhite'],
        green: 'stroke-cMediumGreen',
      },
    },
    defaultVariants: {
      color: 'green',
    },
  }
);
