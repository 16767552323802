'use client';
import {
  CatalogCategoryMaskbar,
  CatalogCategoryMiniformat,
} from '@/entities/catalog-category';
import { IPopularSection } from '@/shared/api/getMainPage/types';
import { Container } from '@/shared/ui/Container';
import { LazyImage } from '@/shared/ui/LazyImage';
import { Link } from '@/shared/ui/Link';
import { SliderNavigationButton } from '@/shared/ui/SliderNavigationButton';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { FC } from 'react';
import { Navigation } from 'swiper/modules';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';

interface Props {
  className?: string;
  items: IPopularSection[];
}

export const CatalogCategoriesSlider: FC<
  Props
> = ({ className, items }) => {
  return (
    <Container
      className={twMergeExt(cvaRoot(), className)}
      variant={'full'}
      inViewAnimation={true}
    >
      <Swiper
        modules={[Navigation]}
        slidesPerView={'auto'}
        slidesOffsetBefore={24}
        slidesOffsetAfter={24}
        breakpoints={{
          0: { spaceBetween: 8 },
          768: { spaceBetween: 12 },
        }}
      >
        {items.map((i) => (
          <SwiperSlide
            key={i.id}
            className={'!w-min'}
          >
            <Link
              className={cvaLink()}
              contentWrapperClassName={cvaLinkWrapper()}
              href={`/products/${i.code}`}
            >
              {i.picture ? (
                <LazyImage
                  wrapClassName={cvaImageWrapper()}
                  className={
                    'object-center object-contain'
                  }
                  src={i.picture}
                  alt={''}
                  fill
                />
              ) : null}
              {{
                mask_bar: (
                  <CatalogCategoryMaskbar />
                ),
                mini_format: (
                  <CatalogCategoryMiniformat />
                ),
              }[i.code] ?? (
                <span className="z-[1]">
                  {i.name}
                </span>
              )}
            </Link>
          </SwiperSlide>
        ))}

        <SliderNavigationButton
          className={cvaNavigationButton({
            direction: 'prev',
          })}
          rootWrapperClassName={cvaNavigationButtonWrapper(
            {
              direction: 'prev',
            }
          )}
          direction={'prev'}
          color={'white'}
        />
        <SliderNavigationButton
          className={cvaNavigationButton({
            direction: 'next',
          })}
          rootWrapperClassName={cvaNavigationButtonWrapper(
            {
              direction: 'next',
            }
          )}
          direction={'next'}
          color={'white'}
        />
      </Swiper>
    </Container>
  );
};

const cvaRoot = cva([
  'CatalogCategoriesSlider-cvaRoot',
  'group',
]);

const cvaLink = cva([
  'CatalogCategoriesSlider-cvaLink',
  'block',
  'pr-2.4',
  'rounded-[2rem] sm:rounded-[3.2rem] overflow-hidden',
  'text-bodyM whitespace-nowrap',
  'bg-cLightGrey',
  'transition-colors duration-300',
  'overflow-hidden',
  'before:pb-[120%] before:absolute before:left-[-10%] before:transform-gpu before:translate-y-[68%] before:scale-0 before:w-[120%] before:h-0 before:rounded-[50%]',
  'after:absolute after:top-0 after:left-0 after:transform-gpu after:translate-y-[-100%] after:w-full after:h-full after:transition-transform after:ease-button-after after:duration-500',
  'hnotdisable:before:translate-y-0 hnotdisable:before:scale-[1.2] hnotdisable:before:transition-transform hnotdisable:before:duration-400 hnotdisable:before:ease-button-before',
  'hnotdisable:after:translate-y-0 hnotdisable:after:[transition-duration:0.05s] hnotdisable:after:[transition-delay:0.4s] hnotdisable:after:transition-transform hnotdisable:after:ease-button-after',
  'before:bg-cMediumGreyOnWhite after:bg-cMediumGreyOnWhite',
  'group',
]);

const cvaLinkWrapper = cva([
  'CatalogCategoriesSlider-cvaLinkWrapper',
  'flex items-center gap-0.8',
  'relative',
]);

const cvaImageWrapper = cva([
  'CatalogCategoriesSlider-cvaImageWrapper',
  'relative',
  'w-6.4 h-6.4 sm:w-8.8 sm:h-8.8',
  'mix-blend-multiply',
  'z-[1]',
  'group-hover:scale-105',
  'transition-all duration-300',
]);

const cvaNavigationButton = cva(
  [
    'CatalogCategoriesSlider-cvaNavigationButton',
    'w-4.8 h-4.8',
    'disabled:opacity-0 disabled:invisible opacity-0 group-hover:opacity-100 hover:border-transparent border-[#213D38] border rounded-[1.6rem]',
    'transition-all duration-300',
  ],
  {
    variants: {
      direction: {
        prev: '',
        next: '',
      },
    },
  }
);

const cvaNavigationButtonWrapper = cva(
  [
    'CatalogCategoriesSlider-cvaNavigationButtonWrapper',
    'absolute top-1/2 -translate-y-1/2',
    'hidden sm:flex',
    'z-[9]',
  ],
  {
    variants: {
      direction: {
        prev: 'left-3.2',
        next: 'right-3.2',
      },
    },
  }
);
